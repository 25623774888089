import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { Fade } from 'react-awesome-reveal';
import LazyLoad from 'react-lazyload';
import ReactMarkdown from 'react-markdown';
import { Box, Flex } from 'rebass/styled-components';
import markdownRenderer from '../MarkdownRenderer';
import TextLibrary from '../TextLibrary';

export enum MediaType {
  IMAGE = 'image',
  VIDEO = 'video',
}

const ProductFeature = ({
  id,
  title,
  summary,
  imageAlign,
  media,
  mediaType,
  textColor,
  isLast = false,
}: {
  id: string;
  title: string;
  summary: string;
  imageAlign: string;
  media: {
    title: string;
    description: string;
    video: {
      contentType: string;
      fileName: string;
      url: string;
    };
    image: ImageDataLike;
  };
  mediaType: MediaType;
  textColor: string;
  isLast?: boolean;
}): JSX.Element => {
  return (
    <>
      <Box
        width="100%"
        margin="auto"
        backgroundColor="light"
        pt={[4.4, 5]}
        pb={isLast ? [6, 6, 7] : [4, 4, 5]}
        id={id}>
        <Flex
          flexDirection={
            imageAlign === 'left'
              ? ['column', 'column', 'row']
              : ['column', 'column', 'row-reverse']
          }
          alignItems="center"
          width="100%"
          px={[3, 0]}>
          <Box
            sx={{ textAlign: imageAlign === 'left' ? 'right' : 'left' }}
            width={[1, 2 / 3, '52.5%']}
            pb={[1, 2, 0]}
            height={['auto']}
            margin="auto">
            {mediaType === MediaType.VIDEO ? (
              typeof window !== 'undefined' && (
                <LazyLoad once offset={100}>
                  <Fade triggerOnce={true} delay={600}>
                    <video loop muted autoPlay playsInline width="100%">
                      <source
                        src={media.video.url}
                        type={media.video.contentType}
                      />
                    </video>
                  </Fade>
                </LazyLoad>
              )
            ) : (
              <Fade triggerOnce={true} delay={600}>
                <GatsbyImage
                  alt={media.description}
                  image={getImage(media.image)!}
                />
              </Fade>
            )}
          </Box>

          <Box width={[1, 2 / 3, '47.5%']} my={[3, 0, 0]} color={textColor}>
            <Fade triggerOnce={true} delay={650}>
              <Flex
                width="100%"
                flexDirection={['column', 'column', 'row']}
                alignItems="center"
                justifyContent={
                  imageAlign === 'left' ? 'flex-start' : 'flex-end'
                }
                pl={imageAlign === 'left' ? [0, 0, 4, 4] : 0}
                pr={imageAlign === 'left' ? 0 : [0, 0, 4, 4]}>
                <Box maxWidth={['480px', '480px', '412px']} ml={[0, 0, 3, 0]}>
                  <TextLibrary.H3
                    mb={[2]}
                    textAlign={['center', 'center', 'left']}>
                    {title}
                  </TextLibrary.H3>
                  <TextLibrary.Paragraph
                    as="span"
                    mb={[2]}
                    textAlign={['center', 'center', 'left']}
                    color="greyHigh">
                    <ReactMarkdown components={markdownRenderer}>
                      {summary}
                    </ReactMarkdown>
                  </TextLibrary.Paragraph>
                </Box>
              </Flex>
            </Fade>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default ProductFeature;
