import React from 'react';
import ContextProvider from '../components/ContextProvider';
import Features from '../components/Features';

const FeaturesPage = ({ localeCode }: { localeCode?: string }) => {
  return (
    <ContextProvider localeCode={localeCode}>
      <Features />
    </ContextProvider>
  );
};

export default FeaturesPage;
