import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext } from 'react';
import { Box } from 'rebass/styled-components';
import { LocaleContext } from '../ContextProvider';
import PromoMedia from '../Home/sections/PromoMedia';
import PromoText from '../Home/sections/PromoText';
import PromoThreePart from '../Home/sections/PromoThreePart';
import Signup from '../Home/sections/Signup';
import Layout from '../Layout';
import ProductFeature from './ProductFeature';

const FeaturesPage = () => {
  const localeData = useContext(LocaleContext);
  const { locale } = localeData;

  const stripeData = useStaticQuery(graphql`
    query FeaturePageComponents {
      enUs: contentfulSiteData(node_locale: { eq: "en-US" }) {
        featuresPage {
          id
          metaTagTitle
          metaTagDescription
          components {
            ... on ContentfulPromoMedia {
              id
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              title
              summary
              imageAlign
              featureList
              media {
                title
                description
                video: file {
                  contentType
                  fileName
                  url
                }
                image: gatsbyImageData(width: 560)
              }
              mediaType
              backgroundColor
              textColor
            }
            ... on ContentfulPromoText {
              id
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              title
              titleTextAlign
              summary
              backgroundColor
              textColor
              callToAction
              summaryTextAlign
            }
            ... on ContentfulPromoThreePart {
              id
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              part1PromoText
              part1BackgroundImage {
                title
                description
                image: gatsbyImageData(width: 1466)
              }
              part1WallImage {
                title
                description
                image: gatsbyImageData(width: 640)
              }
              part1ForegroundImage {
                title
                description
                image: gatsbyImageData(width: 300)
              }
              part2PromoText
              part2BackgroundImage {
                title
                description
                image: gatsbyImageData(width: 1466)
              }
              part3PromoText
              part3BackgroundImage {
                title
                description
                image: gatsbyImageData(width: 350)
              }
              part1TextColor
              part2TextColor
              part3TextColor
              part3BackgroundColor
            }
            ... on ContentfulSignUp {
              id
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              backgroundImage {
                id
                title
                description
                gatsbyImageData(width: 1080)
              }
              callToActionButton {
                id
                label
                onClickType
                onClick
                variant
              }
              sectionId
              title
              titleLine2
              summary
              textColor
              inputId
              inputLabel
              inputLabelColor
              inputPrompt
              callToAction
              terms
              errorText
              errorTextColor
              backgroundColor
              termsTextColor
              promoVideo {
                id
                title
                description
                file {
                  contentType
                  fileName
                  url
                }
              }
              promoVideoStillScreen {
                id
                title
                description
                gatsbyImageData(width: 960)
              }
            }
            ... on ContentfulProductFeature {
              id
              featureId
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              name
              summary
              description {
                childMarkdownRemark {
                  rawMarkdownBody
                }
              }
              media {
                title
                description
                video: file {
                  contentType
                  fileName
                  url
                }
                image: gatsbyImageData(width: 560)
              }
              mediaType
            }
          }
        }
      }
      enAu: contentfulSiteData(node_locale: { eq: "en-AU" }) {
        featuresPage {
          id
          metaTagTitle
          metaTagDescription
          components {
            ... on ContentfulPromoMedia {
              id
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              title
              summary
              imageAlign
              featureList
              media {
                title
                description
                video: file {
                  contentType
                  fileName
                  url
                }
                image: gatsbyImageData(width: 560)
              }
              mediaType
              backgroundColor
              textColor
            }
            ... on ContentfulPromoText {
              id
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              title
              titleTextAlign
              summary
              backgroundColor
              textColor
              callToAction
              summaryTextAlign
            }
            ... on ContentfulPromoThreePart {
              id
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              part1PromoText
              part1BackgroundImage {
                title
                description
                image: gatsbyImageData(width: 1466)
              }
              part1WallImage {
                title
                description
                image: gatsbyImageData(width: 640)
              }
              part1ForegroundImage {
                title
                description
                image: gatsbyImageData(width: 300)
              }
              part2PromoText
              part2BackgroundImage {
                title
                description
                image: gatsbyImageData(width: 1466)
              }
              part3PromoText
              part3BackgroundImage {
                title
                description
                image: gatsbyImageData(width: 350)
              }
              part1TextColor
              part2TextColor
              part3TextColor
              part3BackgroundColor
            }
            ... on ContentfulSignUp {
              id
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              backgroundImage {
                id
                title
                description
                gatsbyImageData(width: 1080)
              }
              callToActionButton {
                id
                label
                onClickType
                onClick
                variant
              }
              sectionId
              title
              titleLine2
              summary
              textColor
              inputId
              inputLabel
              inputLabelColor
              inputPrompt
              callToAction
              terms
              errorText
              errorTextColor
              backgroundColor
              termsTextColor
              promoVideo {
                id
                title
                description
                file {
                  contentType
                  fileName
                  url
                }
              }
              promoVideoStillScreen {
                id
                title
                description
                gatsbyImageData(width: 960)
              }
            }
            ... on ContentfulProductFeature {
              id
              featureId
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              name
              summary
              description {
                childMarkdownRemark {
                  rawMarkdownBody
                }
              }
              media {
                title
                description
                video: file {
                  contentType
                  fileName
                  url
                }
                image: gatsbyImageData(width: 560)
              }
              mediaType
            }
          }
        }
      }
    }
  `);
  let page = stripeData.enUs.featuresPage;
  let { components } = page;

  if (locale === 'en-AU') {
    page = stripeData.enAu.featuresPage;
    components = page.components;
  }

  let alignLeft = true;
  let cnt = 0;

  const pageComponents = components.map((component) => {
    cnt += 1;
    alignLeft = !alignLeft;
    switch (component.sys.contentType.sys.id) {
      case 'signUp':
        return (
          <Signup
            key={component.id}
            id={component.sectionId}
            backgroundImage={component.backgroundImage}
            callToActionButton={component.callToActionButton}
            title={component.title}
            titleLine2={component.titleLine2}
            summary={component.summary}
            textColor={component.textColor}
            callToAction={component.callToActionButton.label}
            inputId={component.inputId}
            inputLabel={component.inputLabel}
            inputLabelColor={component.inputLabelColor}
            inputPrompt={component.inputPrompt}
            terms={component.terms}
            termsTextColor={component.termsTextColor}
            errorText={component.errorText}
            errorTextColor={component.errorTextColor}
            backgroundColor={component.backgroundColor}
            buttonVariant={component.callToActionButton.variant}
            promoVideo={component.promoVideo}
            promoVideoStillScreen={component.promoVideoStillScreen}
          />
        );

      case 'promoText':
        return (
          <PromoText
            key={component.id}
            id={component.id}
            title={component.title}
            titleTextAlign={component.titleTextAlign}
            summary={component.summary}
            backgroundColor={component.backgroundColor}
            textColor={component.textColor}
            callToAction={component.callToAction}
            summaryTextAlign={component.summaryTextAlign}
          />
        );
      case 'PromoThreePart':
        return (
          <PromoThreePart
            key={component.id}
            id={component.id}
            part1PromoText={component.part1PromoText}
            part1TextColor={component.part1TextColor}
            part1BackgroundImage={component.part1BackgroundImage}
            part1WallImage={component.part1WallImage}
            part1ForegroundImage={component.part1ForegroundImage}
            part2PromoText={component.part2PromoText}
            part2TextColor={component.part2TextColor}
            part2BackgroundImage={component.part2BackgroundImage}
            part3PromoText={component.part3PromoText}
            part3TextColor={component.part3TextColor}
            part3BackgroundImage={component.part3BackgroundImage}
            part3BackgroundColor={component.part3BackgroundColor}
          />
        );
      case 'promoImage':
        return (
          <PromoMedia
            key={component.id}
            id={component.id}
            title={component.title}
            summary={component.summary}
            imageAlign={component.imageAlign}
            featureList={component.featureList}
            media={component.media}
            mediaType={component.mediaType}
            textColor={component.textColor}
            backgroundColor={component.backgroundColor}
          />
        );
      case 'productFeature':
        let isLast = false;
        if (components.length === cnt) {
          isLast = true;
        }
        return (
          <ProductFeature
            key={component.id}
            id={component.featureId}
            title={component.name}
            summary={component.description.childMarkdownRemark.rawMarkdownBody}
            imageAlign={alignLeft ? 'left' : 'right'}
            media={component.media}
            mediaType={component.mediaType}
            textColor="dark"
            isLast={isLast}
          />
        );
      default:
        return null;
    }
  });

  // Page layout
  return (
    <Layout
      title={`${page.metaTagTitle} - CloudFit`}
      description={page.metaTagDescription}
      colorTheme="light">
      <Box backgroundColor="light">
        <Box px={[3, 3, 5]} pt={[6]} maxWidth="960px" margin="auto">
          <PromoText
            key={page.id}
            id={page.id}
            title={page.metaTagTitle}
            titleTextAlign="center"
            summary={page.metaTagDescription}
            backgroundColor="light"
            textColor="dark"
            callToAction=""
            summaryTextAlign="center"
          />
        </Box>
      </Box>
      {pageComponents}
    </Layout>
  );
};

export default FeaturesPage;
